import * as THREE from 'three';
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";
import Stats from "stats.js";
import {ColorConverter} from "three/examples/jsm/math/ColorConverter";
import {Loader} from "./objectModuleLoader.js";
import * as PARAMS from "./params";
import {WEBGL} from "three/examples/jsm/WebGL";

let camera, scene, renderer;
let controls;

let timerID;

let VIEWER_CONTAINER;
let VIEWER_PARENT;

let WIDTH;
let HEIGHT;
let pixelRatio;
let screenRatio;
let frameId;

let sparklesPool;
let sparklesMesh;
let visibleBox;
let sparkleTexture;

let meshes;

let renderDisabled = true;

const Viewer = (function () {
    let finishCallback;
    let initiated = false;

    return {
        init() {
            return new Promise(resolve => {
                if (WEBGL.isWebGLAvailable()) {
                    if (initiated) {
                        return Promise.resolve();
                    }

                    VIEWER_CONTAINER = document.getElementById('viewer');
                    VIEWER_PARENT = VIEWER_CONTAINER.parentElement;
                    WIDTH = VIEWER_PARENT.clientWidth;
                    HEIGHT = VIEWER_PARENT.clientHeight;
                    pixelRatio = window.devicePixelRatio || 1;
                    screenRatio = WIDTH / HEIGHT;

                    meshes = meshesCollection();

                    init().then(() => {
                        FrameChecker.start();
                        animate();
                        initiated = true;
                        resolve();
                    });
                } else {
                    console.warn('webgl is not supported');
                    initiated = false;
                    return Promise.resolve();
                }
            });
        },
        destroy() {
            if (!initiated) {
                return;
            }

            while (scene.children.length) {
                let object = scene.children[0];
                scene.remove(object);
                if (object.dispose) {
                    object.dispose();
                }
            }
            removeEvents();

            setTimeout(() => {
                cancelAnimationFrame(frameId);
                VIEWER_CONTAINER = null;
                VIEWER_PARENT = null;

                WIDTH = null;
                HEIGHT = null;
                pixelRatio = null;
                screenRatio = null;

                sparklesPool = null;
                sparklesMesh = null;
                visibleBox = null;
                sparkleTexture = null;

                meshes = null;
                scene.dispose();
                scene = null;

                camera = null;

                renderer.dispose();
                renderer = null;
                initiated = false;
            }, 100);

        },
        onAnimationFinished(callback) {
            finishCallback = callback;
        },
        callFinish() {
            if (finishCallback) {
                finishCallback();
            }
        },
        change(index) {
            if (renderDisabled) {
                this.callFinish();
                return;
            }
            if (index === meshes.getCurrent()) {
                this.callFinish();
                return;
            }

            meshes.next(index);
            meshes.startChange(index);
        }
    }
})();

const FrameChecker = (function () {
    let beginTime;
    let prevTime;
    let frames = 0;
    let fps = 0;
    let stopCheck = false;
    let checkerHandler;
    let onEnd;
    let c = [];

    return {
        updateFrameRate() {
            if (stopCheck) {
                return;
            }

            frames++;
            let time = (performance || Date).now();
            if (time > prevTime + 1000) {
                fps = ((frames * 1000) / (time - prevTime));
                c.push(fps);
                prevTime = time;
                frames = 0;
            }
        },

        getFps() {
            return fps;
        },

        start() {
            beginTime = (performance || Date).now();
            prevTime = beginTime;
            renderer.domElement.parentElement.classList.add('media--check');
            checkerHandler = setTimeout(() => {
                let sum = c.reduce((r, v) => r+=v, 0);
                let avgFps = (sum / c.length);

                if (avgFps < 30) {
                    renderDisabled = true;
                    Viewer.destroy();
                } else {
                    renderDisabled = false;
                    renderer.domElement.parentElement.classList.remove('media--check');
                }

                if (onEnd) {
                    onEnd();
                }

                stopCheck = true;
            }, 3000);
        },

        onEnd(cb) {
            onEnd = cb;
        },

        stopCheck() {
            stopCheck = true;
            clearTimeout(checkerHandler);
        }
    }
})();

function meshesCollection() {
    let collection = [];
    let currentMesh = '';
    let next = 0;
    let current = 0;

    return {
        push(...meshGroups) {
            collection.push(...meshGroups);
        },
        forEach(callback) {
            collection.forEach(callback);
        },
        addToScene(scene) {
            scene.add(...collection.reduce((flat, {meshes}) => flat.concat([
                // meshes.mesh,
                meshes.blinkTrail,
                meshes.edgeMesh
            ]), []))
        },
        setCurrent(index) {
            current = next;
            currentMesh = collection[index];
            collection.forEach(({meshes}, _index) => {
                // meshes.mesh.visible = index === _index;
                meshes.blinkTrail.visible = index === _index;
                meshes.edgeMesh.visible = index === _index;
            })
        },
        animate(rotation) {
            if (!currentMesh) {
                return;
            }
            let meshes = currentMesh.meshes;
            // meshes.mesh.rotation.y += ROTATION;
            if (!isDragging) {
                meshes.blinkTrail.rotation.y += rotation;
                meshes.edgeMesh.rotation.y += rotation;
            }

            currentMesh.effects.forEach(effect => effect.update());
            if (next !== current && currentMesh.effects.every(effect => effect.isFinished())) {
                this.applyNext()
            }
        },
        getCurrent() {
            return current;
        },
        getCurrentMesh() {
            return collection[current];
        },
        next(index) {
            if (next === index) {
                return;
            }

            if (currentMesh.effects.some(effect => effect.inProgress())) {
                return;
            }

            next = index
        },
        applyNext() {
            if (next === current) {
                return;
            }

            this.setCurrent(next);
            this._restoreMesh();
        },

        startChange() {
            if (currentMesh.effects.some(effect => effect.inProgress())) {
                return;
            }

            // currentMesh.meshes.mesh.visible = false;

            currentMesh.effects.forEach(effect => {
                effect.stopOn('restore');
                // effect.apply();
                effect.start();
            })
        },

        _restoreMesh() {
            // currentMesh.meshes.mesh.visible = false;
            currentMesh.effects.forEach(effect => {
                effect.skipTo('restore');
                effect.stopOn('');
                // effect.apply();
                effect.onceOnFinish(() => {
                    Viewer.callFinish();
                })
            });
        }
    }
}

let stats;

if (process.env.NODE_ENV !== 'production') {
    // function initStats() {
    //     let stats = new Stats();
    //     stats.setMode(0); // 0: fps, 1: ms
    //     // // Align top-left
    //     stats.domElement.style.position = 'absolute';
    //     stats.domElement.style.left = '0px';
    //     stats.domElement.style.top = '0px';
    //     document.getElementById("stats-output").appendChild(stats.domElement);
    //     return stats;
    // }
    //
    // stats = initStats();
}

function effects() {
    let totalDuration = 0;
    let effects = [];
    let innerTimer = 0;
    let material;
    let isFinished = false;
    let materialName = '';
    let stopOnEffectName;
    let onFinish = null;

    let currDuration = function () {
        let total = 0;

        return effect => {
            if ((innerTimer - total) > effect.getDuration()) {
                total += effect.getDuration();
                return false;
            }

            return true;
        }
    };

    function stop() {
        effects.forEach(effect => {
            let uniform = effect.getUniform();
            uniform.value = 0;
        });
        innerTimer = 0;
        isFinished = true;
        if (typeof onFinish === 'function') {
            onFinish();
            onFinish = null;
        }
    }

    return {
        onceOnFinish(callback) {
            onFinish = callback;
        },
        start() {
            innerTimer = 1;
            isFinished = false;
        },
        add(...argEffects) {
            effects.push(...argEffects);
            totalDuration = effects.reduce((total, effect) => (total + effect.getDuration()), 0);
        },
        update() {
            if (innerTimer === 0) {
                return;
            }

            innerTimer += 1;

            let currentEffect = effects.find(currDuration());
            if (!currentEffect) {
                return;
            }
            if (currentEffect.name === stopOnEffectName) {
                stop();
                return;
            }
            let uniformForUpdate = currentEffect.getUniform();
            uniformForUpdate.value += 1;

            if (innerTimer >= totalDuration) {
                stop();
            }
        },

        isFinished() {
            return isFinished;
        },

        inProgress() {
            return innerTimer > 0;
        },

        skipTo(name) {
            for (let i = 0; i < effects.length; i++) {
                let effect = effects[i];
                if (effect.name === name) {
                    break;
                } else {
                    innerTimer += effect.getDuration();
                    let uniformForUpdate = effect.getUniform();
                    uniformForUpdate.value = effect.getDuration();
                }
            }
        },

        stopOn(name) {
            stopOnEffectName = name;
        },

        toMaterial(shaderMaterial, shaderMaterialName) {
            material = shaderMaterial;
            materialName = shaderMaterialName;
        },

        apply(materialName) {
            let shaderCode = '';
            if (materialName === 'trail') {
                shaderCode = `
                    varying vec3 vPosition;
                    uniform float time;
                    uniform float dotsCount;
                    uniform float k;
                    
                    attribute float pointIndex;
                    
                    varying vec4 vecPosition;
                    
                    ${this.getShaderHeader()}
                    
                    void main() {
                    
                        vec3 newPosition = position;
                    
                        newPosition.x += sin((time + newPosition.x + pointIndex * k / dotsCount) / 10.0) + pointIndex / 5.0 - 2.0;
                        newPosition.y += abs(sin((time + pointIndex * k / dotsCount) / 2.5)) - 0.5;
                        
                        ${this.getShaderBody()}
                        
                        vPosition = newPosition;
                        gl_PointSize = pointIndex + pointIndex / dotsCount;
                        gl_Position = projectionMatrix * modelViewMatrix * vec4( newPosition, 1.0 );
                        vecPosition = gl_Position;
                    }
                `;
            } else {
                shaderCode = `
                    ${this.getShaderHeader()}
                    varying vec3 vPosition;
                    varying vec4 vecPosition;
    
                    void main() {
                        vec3 newPosition = position;
                        ${this.getShaderBody()}
    
                        vPosition = newPosition;
                        vec4 mvPosition = modelViewMatrix * vec4( newPosition, 1.0 );
                        gl_Position = projectionMatrix * mvPosition;
                        gl_PointSize = 2.0 * (300.0 / -mvPosition.z);
                        vecPosition = gl_Position;
                    }
                `
            }
            return shaderCode;
        },

        getShaderHeader() {
            let parts = effects.map(effect => effect.getShaderHeader());
            return parts.join('\n');
        },

        getShaderBody() {
            let parts = effects.map(effect => effect.getShaderPosition());
            return parts.join('\n');
        },
    }
}

let edgeUniforms = {
    color: {
        value: PARAMS.COLOR
    },
    time: {
        value: 1
    },
    collapseTime: {
        value: 0
    },
    shadowNear: {
        value: PARAMS.SHADOW_PARAMS.SHADOW_NEAR
    },
    shadowFar: {
        value: PARAMS.SHADOW_PARAMS.SHADOW_FAR
    },
    shadowUpperValue: {
        value: PARAMS.SHADOW_PARAMS.SHADOW_UPPER_VALUE
    },
    shadowUpperCorrection: {
        value: PARAMS.SHADOW_PARAMS.SHADOW_UPPER_CORRECTION
    },
    shadowLowerValue: {
        value: PARAMS.SHADOW_PARAMS.SHADOW_LOWER_VALUE
    },
    shadowLowerCorrection: {
        value: PARAMS.SHADOW_PARAMS.SHADOW_LOWER_CORRECTION
    },
};

let blinkUniforms = {
    color: {
        value: PARAMS.COLOR
    },
    time: {
        value: 1
    },
    colorTime: {
        value: 1
    },
    dotsCount: {
        value: 10
    },
    k: {
        value: 5.0
    },
    pointTexture: {value: sparkleTexture},
    shadowNear: {
        value: PARAMS.SHADOW_PARAMS.SHADOW_NEAR
    },
    shadowFar: {
        value: PARAMS.SHADOW_PARAMS.SHADOW_FAR
    },
    shadowUpperValue: {
        value: PARAMS.SHADOW_PARAMS.SHADOW_UPPER_VALUE
    },
    shadowUpperCorrection: {
        value: PARAMS.SHADOW_PARAMS.SHADOW_UPPER_CORRECTION
    },
    shadowLowerValue: {
        value: PARAMS.SHADOW_PARAMS.SHADOW_LOWER_VALUE
    },
    shadowLowerCorrection: {
        value: PARAMS.SHADOW_PARAMS.SHADOW_LOWER_CORRECTION
    },
};

function effect(name, duration, direction = 1) {
    let effectName = name;
    let effectDuration = duration;
    let effectDirection = direction;
    let startVertices = [];
    let targetVertices = [];
    let effectUniforms;

    return {
        name: name,
        getUniform() {
            return effectUniforms[`${effectName}Time`];
        },
        getDuration() {
            return effectDuration;
        },
        getShaderHeader() {
            return `
                        attribute vec3 ${effectName}TargetVector;
                        attribute vec3 ${effectName}StartVertex;
                        
                        uniform float ${effectName}Time;
                        uniform float ${effectName}Duration;
                        uniform float ${effectName}Direction;
                    `;
        },
        getShaderPosition() {
            if (name === 'spread2' || name === 'restore') {
                return `
                    if (${effectName}Time > 0.0) {
                        vec3 ${effectName}OffsetVector = ${effectName}TargetVector - newPosition;
                        float ${effectName}Step = (1.0 / (length(${effectName}OffsetVector)) * 0.6) + ${effectName}Time / ${effectName}Duration;
                        newPosition = newPosition + length(${effectName}OffsetVector) * normalize(${effectName}OffsetVector) * ${effectName}Step;
                    }
                `;
            }

            if (effectDirection === -1) {
                return `
                        vec3 ${effectName}OffsetVector = getOffsetVertex(${effectName}TargetVector, ${name === 'restore' ? 'newPosition' : `${effectName}StartVertex`}, ${effectName}Duration, ${effectName}Direction);
                        newPosition = newPosition + ${effectName}OffsetVector * ${effectName}Time;
                        if (${effectName}Time > 0.0 && length(newPosition) < length(${effectName}TargetVector)) {
                            newPosition = ${effectName}TargetVector;
                        }
                        `;
            }

            return `
                        vec3 ${effectName}OffsetVector = getOffsetVertex(${effectName}TargetVector, ${effectName}StartVertex, ${effectName}Duration, ${effectName}Direction);
                        newPosition = newPosition + ${effectName}OffsetVector * ${effectName}Time;
                        if (${effectName}Time > 0.0 && length(newPosition) > length(${effectName}TargetVector)) {
                            newPosition = ${effectName}TargetVector;
                        }
                    `;
        },
        setAttribute(geometry, uniforms) {
            effectUniforms = uniforms;
            geometry.setAttribute(`${effectName}StartVertex`, new THREE.BufferAttribute(new Float32Array(startVertices), 3));
            geometry.setAttribute(`${effectName}TargetVector`, new THREE.BufferAttribute(new Float32Array(targetVertices), 3));
            effectUniforms[`${effectName}Time`] = {value: 0};
            effectUniforms[`${effectName}Duration`] = {value: effectDuration};
            effectUniforms[`${effectName}Direction`] = {value: effectDirection}
        },
        apply(vertex, sphereRadius = 10, sphereOffset = 5) {
            let vertexInsideSphere;
            let startVertex = vertex;
            if (name === 'collapse') {
                vertexInsideSphere = vertex.clone().normalize();
            } else if (name === 'restore') {
                vertexInsideSphere = vertex.clone();
            } else if (name === 'explode') {
                vertexInsideSphere = vertex.clone().negate().normalize().multiplyScalar(sphereRadius).sub(randomUnitVector().multiplyScalar(sphereOffset));
            } else {
                vertexInsideSphere = randomUnitVector().normalize().multiplyScalar(sphereRadius).sub(randomUnitVector().multiplyScalar(sphereOffset));
            }

            if (name === 'spread2') {
                vertexInsideSphere = vertex.clone().sub(randomUnitVector());
                // vertexInsideSphere;
                // rand(0.9, 1) - смещение точки внутрь сферы. Умножение на случайную величину от (min, max)
                vertexInsideSphere.multiplyScalar(rand(PARAMS.SPREAD_PARAMS.OFFSET_MIN, PARAMS.SPREAD_PARAMS.OFFSET_MAX));
                // точки которые вылетели слишком близко к центру - возвращаем обратно.
                if (vertexInsideSphere.length < PARAMS.SPREAD_PARAMS.CENTER_OFFSET) {
                    vertexInsideSphere.addScalar(PARAMS.SPREAD_PARAMS.CENTER_OFFSET_ROLLBACK)
                }
                // увеличиваем сферу в n-раз
                vertexInsideSphere.multiplyScalar(PARAMS.SPREAD_PARAMS.SCALE);
            }

            startVertices.push(...startVertex.toArray());
            targetVertices.push(...vertexInsideSphere.toArray());
        }
    }
}

function initMesh(mesh) {
    let edgeEffects = effects();
    let blinkTrailEffect = effects();

    const obj = mesh.isGroup ? mesh.children[0] : mesh;

    let blinkTrail = createBlinkTrailMesh(obj, blinkTrailEffect);
    let edgeMesh = createEdgeMesh(obj, edgeEffects);

    meshes.push({
        meshes: {
            // mesh,
            blinkTrail,
            edgeMesh
        },
        effects: [
            edgeEffects,
            blinkTrailEffect
        ]
    });
}

function createBlinkTrailMesh(obj, effects) {
    let vertices = obj.geometry.attributes.position.array;
    let verticesCount = obj.geometry.attributes.position.count;

    let resultVertices = [];
    let indexes = [];

    let spread2 = effect('spread2', PARAMS.SPREAD_DURATION);
    let restore = effect('restore', PARAMS.RESTORE_DURATION, -1);

    for (let i = 0; i < verticesCount; i++) {
        let vertex = new THREE.Vector3(vertices[i * 3], vertices[i * 3 + 1], vertices[i * 3 + 2]).applyMatrix4(obj.matrixWorld);
        for (let j = 0; j < blinkUniforms.dotsCount.value; j++) {
            indexes.push(j + 1);
            resultVertices.push(...vertex.toArray());
            let randomVector = randomUnitVector();
            spread2.apply(randomVector);
            restore.apply(vertex);
        }
    }

    let bufferGeometry = new THREE.BufferGeometry();
    bufferGeometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array(resultVertices), 3));
    bufferGeometry.setAttribute('pointIndex', new THREE.BufferAttribute(new Uint8Array(indexes), 1));
    spread2.setAttribute(bufferGeometry, blinkUniforms);
    restore.setAttribute(bufferGeometry, blinkUniforms);

    effects.add(
        spread2,
        restore
    );

    const fragmentShader = `
        uniform vec3 color;
        uniform float colorTime;
        uniform sampler2D pointTexture;
        
        varying vec3 vPosition;
        varying vec4 vecPosition;
        
        uniform float shadowNear;
        uniform float shadowFar;
        uniform float shadowUpperValue;
        uniform float shadowUpperCorrection;
        uniform float shadowLowerValue;
        uniform float shadowLowerCorrection;
        
        vec3 rgb2hsv(vec3 c)
        {
            vec4 K = vec4(0.0, -1.0 / 3.0, 2.0 / 3.0, -1.0);
            vec4 p = mix(vec4(c.bg, K.wz), vec4(c.gb, K.xy), step(c.b, c.g));
            vec4 q = mix(vec4(p.xyw, c.r), vec4(c.r, p.yzx), step(p.x, c.r));
        
            float d = q.x - min(q.w, q.y);
            float e = 1.0e-10;
            return vec3(abs(q.z + (q.w - q.y) / (6.0 * d + e)), d / (q.x + e), q.x);
        }
        
        vec3 hsv2rgb(vec3 c)
        {
            vec4 K = vec4(1.0, 2.0 / 3.0, 1.0 / 3.0, 3.0);
            vec3 p = abs(fract(c.xxx + K.xyz) * 6.0 - K.www);
            return c.z * mix(K.xxx, clamp(p - K.xxx, 0.0, 1.0), c.y);
        }
        
        float upperValue = 0.65;
        float lowerValue = 0.65;
        
        void main() {
            // Смещение для цвета по x компоненте
            float offsetByPositionX = abs(sin((colorTime + vPosition.x) / 2.0)) + 0.1;
            // Смещение для цвета по z компоненте
            float offsetByPositionZ = abs(sin((colorTime + vPosition.z) / 1.5)) + 0.2;
        
            vec3 resultColor = vec3(color);
            vec3 hsvColor = rgb2hsv(resultColor);
            
            // Яркость точки в зависимости от z компоненты 
            hsvColor.z = (1.0 - (abs(vecPosition.z) - shadowNear) / shadowFar);
            
            // Выше верхнего значения якрости выставляем яркость в 1. Передняя граница затенения 
            if (hsvColor.z > shadowUpperValue) {
                hsvColor.z = shadowUpperCorrection;
            }
            
            // Ниже нижнего значения якрости уменьшаем еще сильнее. Задняя граница затенения
            if (hsvColor.z < shadowLowerValue) {
                hsvColor.z -= shadowLowerCorrection;
            }
        
            resultColor = hsv2rgb(hsvColor) * offsetByPositionX * offsetByPositionZ;
        
            gl_FragColor = vec4( resultColor, 1.0 ) * texture2D( pointTexture, gl_PointCoord );
        }
    `;

    let shaderMaterial = new THREE.ShaderMaterial({
        uniforms: blinkUniforms,
        vertexShader: effects.apply('trail'),
        fragmentShader,
        blending: THREE.AdditiveBlending,
        depthTest: false,
        transparent: true,
    });
    bufferGeometry.computeBoundingBox();

    return new THREE.Points(bufferGeometry, shaderMaterial);
}

function createEdgeMesh(obj, effects) {
    let vertices = obj.geometry.attributes.position.array;
    let verticesCount = obj.geometry.attributes.position.count;

    let edges = new Map();

    let resultVertices = [];

    let spread2 = effect('spread2', PARAMS.SPREAD_DURATION);
    let restore = effect('restore', PARAMS.RESTORE_DURATION, -1);


    let edgesSum = 0;

    function combine(keyOne, keyTwo) {
        let key = `${keyOne}_${keyTwo}`;
        if (edges.has(key)) {
            return;
        }

        let startVertex = new THREE.Vector3(vertices[keyOne * 3], vertices[keyOne * 3 + 1], vertices[keyOne * 3 + 2]).applyMatrix4(obj.matrixWorld);
        let endVertex = new THREE.Vector3(vertices[keyTwo * 3], vertices[keyTwo * 3 + 1], vertices[keyTwo * 3 + 2]).applyMatrix4(obj.matrixWorld);

        let edgeVertex = new THREE.Vector3();
        edgeVertex.subVectors(endVertex, startVertex);
        let edgeLength = edgeVertex.length();
        let edgeNormal = edgeVertex.normalize();
        edgesSum += edgeLength;

        edges.set(key, {
            startVertex,
            endVertex,
            edgeVertex,
            edgeLength,
            edgeNormal,
        });
    }

    // Формирование индексов фейсов
    for (let i = 0; i < verticesCount; i += 3) {
        let first = i;
        let second = i + 1;
        let third = i + 2;

        combine(first, second);
        combine(first, third);
        combine(second, third);
    }

    let globalStep = PARAMS.PARTICLES_COUNT / edgesSum;
    let particlesDensity = Math.abs(PARAMS.PARTICLES_DENSITY / 2);

    edges.forEach(({startVertex, edgeLength, edgeNormal}) => {
        let step = 1 / (globalStep * edgeLength);
        for (let i = 0; i <= 1; i += step) {
            // A + normal*t*length
            let resultVertex = (new THREE.Vector3()).addVectors(startVertex, edgeNormal.clone().multiplyScalar(edgeLength * i));
            let randomVector = new THREE.Vector3(
                rand(-particlesDensity, particlesDensity),
                rand(-particlesDensity, particlesDensity),
                rand(-particlesDensity, particlesDensity)
            );
            resultVertex.add(randomVector);
            resultVertices.push(...resultVertex.toArray());

            spread2.apply(randomVector);
            restore.apply(resultVertex);
        }
    });

    let bufferGeometry = new THREE.BufferGeometry();
    bufferGeometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array(resultVertices), 3));
    restore.setAttribute(bufferGeometry, edgeUniforms);
    spread2.setAttribute(bufferGeometry, edgeUniforms);

    effects.add(
        spread2,
        restore
    );

    const fragmentShader = `
        uniform vec3 color;
        uniform float time;
        uniform float shadowNear;
        uniform float shadowFar;
        uniform float shadowUpperValue;
        uniform float shadowUpperCorrection;
        uniform float shadowLowerValue;
        uniform float shadowLowerCorrection;
        
        varying vec3 vPosition;
        varying vec4 vecPosition;
        
        vec3 rgb2hsv(vec3 c)
        {
            vec4 K = vec4(0.0, -1.0 / 3.0, 2.0 / 3.0, -1.0);
            vec4 p = mix(vec4(c.bg, K.wz), vec4(c.gb, K.xy), step(c.b, c.g));
            vec4 q = mix(vec4(p.xyw, c.r), vec4(c.r, p.yzx), step(p.x, c.r));
        
            float d = q.x - min(q.w, q.y);
            float e = 1.0e-10;
            return vec3(abs(q.z + (q.w - q.y) / (6.0 * d + e)), d / (q.x + e), q.x);
        }
        
        vec3 hsv2rgb(vec3 c)
        {
            vec4 K = vec4(1.0, 2.0 / 3.0, 1.0 / 3.0, 3.0);
            vec3 p = abs(fract(c.xxx + K.xyz) * 6.0 - K.www);
            return c.z * mix(K.xxx, clamp(p - K.xxx, 0.0, 1.0), c.y);
        }
        
        void main() {
            // Смещение для цвета по x компоненте
            float offsetByPositionX = abs(sin(time * vPosition.x));
            // Смещение для цвета по z компоненте
            float offsetByPositionZ = abs(sin(time * vPosition.z));
        
            vec3 resultColor = vec3(color);
            vec3 hsvColor = rgb2hsv(resultColor);
            // Яркость точки в зависимости от z компоненты 
            hsvColor.z = (1.0 - (abs(vecPosition.z) - shadowNear) / shadowFar);
            
            // Выше верхнего значения якрости выставляем яркость в 1. Передняя граница затенения 
            if (hsvColor.z > shadowUpperValue) {
                hsvColor.z = shadowUpperCorrection;
            }
            
            // Ниже нижнего значения якрости уменьшаем еще сильнее. Задняя граница затенения
            if (hsvColor.z < shadowLowerValue) {
                hsvColor.z -= shadowLowerCorrection;
            }
            
            resultColor = hsv2rgb(hsvColor) * offsetByPositionX * offsetByPositionZ;
            gl_FragColor = vec4( resultColor, 1.0);
        }
    `;

    let shaderMaterial = new THREE.ShaderMaterial({
        uniforms: edgeUniforms,
        vertexShader: effects.apply(),
        fragmentShader,
        transparent: true,
        blending: THREE.AdditiveBlending,
        depthTest: false,
    });
    bufferGeometry.computeBoundingBox();
    effects.toMaterial(shaderMaterial);

    return new THREE.Points(bufferGeometry, shaderMaterial);
}

function load(loader, obj, scale) {
    return new Promise(resolve => {
        loader.load(obj, (mesh) => {
            mesh.scale.set(scale, scale, scale);
            mesh.updateMatrixWorld(true);
            initMesh(mesh);
            resolve()
        }, () => {
        }, () => {
        });
    })
}

function createSparkles() {
    const REDUCE_SPEED = 1 / 60;
    const randWithOffset = () => Math.pow(Math.random(), 1 / 3);
    const randomVertex = function () {
        return new THREE.Vector3(
            randWithOffset() * (0.95 * visibleBox.max.x - visibleBox.min.x) + visibleBox.min.x,
            rand(visibleBox.min.y, visibleBox.max.y),
            rand(-1500, -400),
        )
    };

    class Steps {
        constructor(lifeTime, steps) {
            let step = lifeTime / steps;
            this._steps = [];
            for (let i = 0; i < lifeTime; i += step) {
                this._steps.push(i);
            }
        }

        stepPassed(lifeTime) {
            if (lifeTime < this._steps[this._steps.length - 1]) {
                this._steps.pop();
                return true;
            }

            return false;
        }
    }

    class Sparkle {
        /**
         * @param {Vector3} vertex
         * @param {number} lifeTime
         */
        constructor(vertex, lifeTime) {
            this._vertex = vertex;
            this._lifeTime = lifeTime;
            this._directionVertex = this._makeDirectionVertex();
            this._steps = new Steps(lifeTime, 3);
            this._color = PARAMS.COLOR;
        }

        static RandomSparkle() {
            let lifeTime = Math.random() * (4 - 1) + 1;
            return new Sparkle(randomVertex(), lifeTime);
        }

        walk() {
            this._randomWalk();
            this._reduceLifeTime();
            this._reduceColor()
        }

        notFinished() {
            return this._lifeTime > 0;
        }

        vertexArray() {
            return this._vertex.toArray();
        }

        colorArray() {
            return this._color.toArray();
        }

        size() {
            return this._fade(this._lifeTime);
        }

        /**
         * @return {number}
         * @private
         */
        _getRandomPosition() {
            return Math.random() < 0.5 ? -Math.random() : Math.random();
        }

        _makeDirectionVertex(scale = 0.3) {
            return new THREE.Vector3(Math.random() * scale, Math.random() * scale, -Math.random() * 0.5);
        }

        _randomWalk() {
            if (this._steps.stepPassed(this._lifeTime)) {
                let v = this._makeDirectionVertex();
                if (Math.random() > 0.5) {
                    this._directionVertex = v;
                } else {
                    v.x *= -1;
                    v.y *= -1;
                    this._directionVertex = v;
                }
            }

            this._vertex.add(this._directionVertex);
        }

        _fade(value) {
            return (Math.atan((value - 0.3) * 5.2) / 3.14) * 1.2 + 0.4;
        }

        _reduceColor() {
            let value = this._fade(this._lifeTime);
            let {h, s} = this._color.getHSL({h: 0, s: 0, l: 0});
            this._color = ColorConverter.setHSV(this._color, h, s, value);
        }

        _reduceLifeTime() {
            this._lifeTime -= REDUCE_SPEED;
        }
    }

    class SparklesPool {
        constructor() {
            /**
             * @type {Sparkle[]}
             * @private
             */
            this._pool = [];
            this._timer = 1;
        }

        getVerticesArray() {
            return new Float32Array(this._pool.reduce((result, sparkle) => result.concat(sparkle.vertexArray()), []))
        }

        getColorArray() {
            return new Float32Array(this._pool.reduce((result, sparkle) => result.concat(sparkle.colorArray()), []))
        }

        getSizeArray() {
            return new Float32Array(this._pool.reduce((result, sparkle) => result.concat(sparkle.size()), []))
        }

        /**
         * @param {Sparkle} sparkle
         */
        add(sparkle) {
            this._pool.push(sparkle);
        }

        update(timer) {
            this._timer += timer;
            this._pool.forEach(sparkle => sparkle.walk());
            this.clear();
            this.fill()
        }

        clear() {
            this._pool = this._pool.filter(sparkle => sparkle.notFinished())
        }

        fill() {
            let count = PARAMS.SPARKLES_COUNT - this._pool.length;
            for (let i = 0; i < count; i++) {
                this.add(Sparkle.RandomSparkle());
            }
        }
    }

    sparklesPool = new SparklesPool();
    sparklesPool.fill();

    let bufferGeometry = new THREE.BufferGeometry();
    let attribute = new THREE.BufferAttribute(new Float32Array(sparklesPool.getVerticesArray()), 3);
    let color = new THREE.BufferAttribute(new Float32Array(sparklesPool.getColorArray()), 3);
    let size = new THREE.BufferAttribute(new Float32Array(sparklesPool.getSizeArray()), 1);
    attribute.setUsage(THREE.DynamicDrawUsage);
    color.setUsage(THREE.DynamicDrawUsage);
    size.setUsage(THREE.DynamicDrawUsage);
    bufferGeometry.setAttribute('position', attribute);
    bufferGeometry.setAttribute('customColor', color);
    bufferGeometry.setAttribute('size', size);

    let shaderMaterial = new THREE.ShaderMaterial({
        uniforms: {
            pointTexture: {value: sparkleTexture}
        },
        vertexShader: `
            attribute float size;
            attribute vec3 customColor;
            
            varying vec3 vColor;
            
            void main() {
                vColor = customColor;
                vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
                gl_PointSize = 50.0 * size * (300.0 / -mvPosition.z);
                gl_Position = projectionMatrix * mvPosition;
             }
            `,
        fragmentShader: `
            uniform sampler2D pointTexture;
            varying vec3 vColor;
            
            void main() {
                gl_FragColor = vec4( vColor, 1.0 );
                gl_FragColor = gl_FragColor * texture2D( pointTexture, gl_PointCoord );
            }
            `,
        blending: THREE.AdditiveBlending,
        depthTest: false,
        transparent: true,
    });

    sparklesMesh = new THREE.Points(bufferGeometry, shaderMaterial);

    camera.add(sparklesMesh);
}

async function init() {
    scene = new THREE.Scene();
    camera = new THREE.PerspectiveCamera(55, window.innerWidth / window.innerHeight, 1, 2000);
    camera.position.z = 400;
    camera.aspect = screenRatio;
    scene.add(camera);

    let loader = Loader(THREE);

    for (let i = 0; i < PARAMS.MODELS.length; i++) {
        await load(loader, PARAMS.MODELS[i], 25);
    }

    sparkleTexture = new THREE.TextureLoader().load("models/spark1.png");
    sparkleTexture.wrapS = THREE.RepeatWrapping;
    sparkleTexture.wrapT = THREE.RepeatWrapping;
    blinkUniforms.pointTexture.value = sparkleTexture;

    renderer = new THREE.WebGLRenderer({canvas: VIEWER_CONTAINER, antialias: true, alpha: true});
    renderer.setSize(WIDTH, HEIGHT);
    // renderer.setPixelRatio(1);
    renderer.setClearColor(0x04080D, 1);

    visibleBox = getVisibleBox(-800);

    createSparkles();

    // OrbitControls

    // controls = new OrbitControls(camera, renderer.domElement);
    // // controls.enableDamping = true; // an animation loop is required when either damping or auto-rotation are enabled
    // // controls.dampingFactor = 0.05;
    // controls.screenSpacePanning = false;
    // controls.enableZoom = false;
    // // controls.enablePan = false;
    // controls.enableKeys = false;
    // controls.minDistance = 100;
    // controls.maxDistance = 2000;
    // controls.maxPolarAngle = Math.PI / 2;

    meshes.addToScene(scene);
    meshes.setCurrent(0);
    initEvents();
    updateRenderer();
}

function initEvents() {
    VIEWER_CONTAINER.addEventListener('mousedown', startDrag);
    VIEWER_CONTAINER.addEventListener('touchstart', startDrag);
    VIEWER_CONTAINER.addEventListener('mousemove', rotateToMouse);
    VIEWER_CONTAINER.addEventListener('touchmove', rotateToTouch);
    document.addEventListener('mouseup', stopDrag);
    document.addEventListener('touchend', stopDrag);

    window.addEventListener('resize', onWindowResize);
}

function removeEvents() {
    VIEWER_CONTAINER.removeEventListener('mousedown', startDrag);
    VIEWER_CONTAINER.removeEventListener('touchstart', startDrag);
    VIEWER_CONTAINER.removeEventListener('mousemove', rotateToMouse);
    VIEWER_CONTAINER.removeEventListener('touchmove', rotateToTouch);
    document.removeEventListener('mouseup', stopDrag);
    document.removeEventListener('touchend', stopDrag);

    window.removeEventListener('resize', onWindowResize);
}

function randomUnitVector() {
    return new THREE.Vector3(rand(-1, 1), rand(-1, 1), rand(-1, 1));
}

function getVisibleBox(z) {
    let t = Math.tan(THREE.Math.degToRad(camera.fov) / 2);
    let h = t * z;
    let w = h * camera.aspect;
    return new THREE.Box2(new THREE.Vector2(w, h), new THREE.Vector2(-w, -h));
}

function rand(min, max) {
    return Math.random() * (max - min) + min;
}

let isDragging = false;
let previousMousePosition = {
    x: 0,
    y: 0
};

function toRadians(angle) {
    return angle * (Math.PI / 180);
}

function startDrag(e) {
    if (e.touches) {
        previousMousePosition = {
            x: e.touches[0].pageX,
            y: e.touches[0].pageY
        };
    }
    isDragging = true
}

function stopDrag() {
    isDragging = false;
}

function rotateToTouch(e) {
    let deltaMove = {
        x: e.touches[0].pageX - previousMousePosition.x,
        y: e.touches[0].pageY - previousMousePosition.y
    };

    previousMousePosition = {
        x: e.touches[0].pageX,
        y: e.touches[0].pageY
    };

    rotate(deltaMove);
}

function rotateToMouse(e) {
    let deltaMove = {
        x: e.offsetX - previousMousePosition.x,
        y: e.offsetY - previousMousePosition.y
    };

    previousMousePosition = {
        x: e.offsetX,
        y: e.offsetY
    };

    rotate(deltaMove);
}

const HALF_PI = Math.PI / 2;

function rotate(deltaMove) {
    if (isDragging) {
        let deltaRotation = new THREE.Vector3(
            toRadians(deltaMove.y * 1),
            toRadians(deltaMove.x * 1),
            0);
        let _meshes = meshes.getCurrentMesh();
        for (let mesh of Object.values(_meshes.meshes)) {
            let newRotation = mesh.rotation.toVector3().add(deltaRotation);
            newRotation.x = Math.max(0, Math.min(newRotation.x, HALF_PI));
            mesh.rotation.setFromVector3(newRotation, 'XYZ');
        }
    }
}

function animate() {
    frameId = requestAnimationFrame(animate);
    // controls.update();
    if (stats) {
        stats.update();
    }

    meshes.animate(PARAMS.ROTATION);

    edgeUniforms.time.value += 0.001;

    blinkUniforms.time.value += 0.09;
    blinkUniforms.colorTime.value += 0.01;

    sparklesPool.update(1);

    sparklesMesh.geometry.attributes.position.array = sparklesPool.getVerticesArray();
    sparklesMesh.geometry.attributes.customColor.array = sparklesPool.getColorArray();
    sparklesMesh.geometry.attributes.size.array = sparklesPool.getSizeArray();
    sparklesMesh.geometry.attributes.position.needsUpdate = true;
    sparklesMesh.geometry.attributes.customColor.needsUpdate = true;
    sparklesMesh.geometry.attributes.size.needsUpdate = true;

    renderer.render(scene, camera);
    FrameChecker.updateFrameRate();
}

function updateRenderer() {
    WIDTH = VIEWER_PARENT.clientWidth;
    HEIGHT = VIEWER_PARENT.clientHeight;

    pixelRatio = window.devicePixelRatio || 2;
    screenRatio = WIDTH / HEIGHT;

    camera.aspect = screenRatio;
    camera.updateProjectionMatrix();
    camera.updateMatrixWorld();

    visibleBox = getVisibleBox(-800);
    let vb = getVisibleBox(400);

    if (WIDTH <= 768 && screenRatio < 1) {
        let v3 = new THREE.Vector3(
            ((WIDTH / 2) / WIDTH) * 2 - 1,
            -(((HEIGHT / 4) + 50) / HEIGHT) * 2 + 1,
            0.5);
        let yOffset = Math.abs(vb.max.y * v3.y);

        meshes.forEach(group => {
            Object.values(group.meshes).forEach(mesh => {
                mesh.position.set(0, yOffset, 0);
                mesh.scale.set(screenRatio, screenRatio, screenRatio);
                mesh.updateMatrixWorld();
            });
        });
    } else {
        let v3 = new THREE.Vector3(
            ((WIDTH * 0.9) / WIDTH) * 2 - 1,
            -((HEIGHT / 2) / HEIGHT) * 2 + 1,
            0.5);
        let xOffset = Math.abs(vb.max.x * v3.x);
        meshes.forEach(group => {
            Object.values(group.meshes).forEach(mesh => {
                if (screenRatio > 1) {
                    mesh.position.set(xOffset - mesh.geometry.boundingBox.max.x, 0, 0);
                } else {
                    mesh.position.set(0, 0, 0);
                }
                mesh.scale.set(1, 1, 1);
                mesh.updateMatrixWorld();
            });
        });
    }

    renderer.setSize(WIDTH, HEIGHT);
}

function onWindowResize() {
    clearTimeout(timerID);
    timerID = setTimeout(function () {
        updateRenderer();
    }, 100);
}

export default Viewer;
