import * as THREE from "three";

/**
 * параметр вращения модели
 *
 * @type {number}
 */
export const ROTATION = 0.001;

/**
 * Количество случайных затухающих точек на экране
 *
 * @type {number}
 */
export const SPARKLES_COUNT = 50;

/**
 * Время анимации распада модели
 *
 * @type {number}
 */
export const SPREAD_DURATION = 60;

/**
 * Смещение точки внутрь сферы. Умножение на случайную величину от (OFFSET_MIN, OFFSET_MAX)
 *
 * Точкам, которые вылетели к центру ближе чем CENTER_OFFSET, добавляем смещение CENTER_OFFSET_ROLLBACK, чтобы убрать подальше от центра.
 *
 * Увеличиваем сферу в SCALE раз
 *
 * @type {{OFFSET_MIN: number, CENTER_OFFSET_ROLLBACK: number, OFFSET_MAX: number, SCALE: number, CENTER_OFFSET: number}}
 */
export const SPREAD_PARAMS = {
    OFFSET_MIN: 0.9,
    OFFSET_MAX: 1,
    CENTER_OFFSET: 0.9,
    CENTER_OFFSET_ROLLBACK: 0.5,
    SCALE: 150
};

/**
 * Время анимации воостановления модели
 *
 * @type {number}
 */
export const RESTORE_DURATION = 40;

/**
 * Общее кол-во точек, распередляемых по граням
 *
 * @type {number}
 */
export const PARTICLES_COUNT = 10000;

/**
 * Параметр плотности точек. Чем меньше тем плотнее.
 *
 * @type {number}
 */
export const PARTICLES_DENSITY = 1.5;

/**
 * Цвет точки
 * @type {Color}
 */
export const COLOR = new THREE.Color(0, 0.7524626358695652, 0.5281297794480062);

/**
 * Список моделей
 *
 * @type {*[]}
 */
export const MODELS = [
    'models/model1.obj',
    'models/model2.obj',
    'models/model3.obj',
    'models/model4.obj',
    'models/model5.obj',
];

/**
 * Параметры области затенения.
 * Область ограниченная SHADOW_NEAR и SHADOW_FAR на которую проецирутеся z-компонента.
 *
 * Если проекция больше SHADOW_UPPER_VALUE, то это ближняя граница затемнения - выставляем ее в SHADOW_UPPER_CORRECTION. Точка будет светлее
 * Если проекция меньше SHADOW_LOWER_VALUE, то это дальняя граница затемнения - вычитаем из нее SHADOW_LOWER_CORRECTION. Точка будет темнее
 *
 * @type {{SHADOW_FAR: number, SHADOW_UPPER_VALUE: number, SHADOW_LOWER_CORRECTION: number, SHADOW_LOWER_VALUE: number, SHADOW_NEAR: number, SHADOW_UPPER_CORRECTION: number}}
 */
export const SHADOW_PARAMS = {
    SHADOW_NEAR: 110,
    SHADOW_FAR: 800,
    SHADOW_UPPER_VALUE: 0.65,
    SHADOW_UPPER_CORRECTION: 1,
    SHADOW_LOWER_VALUE: 0.65,
    SHADOW_LOWER_CORRECTION: 0.2,
};
