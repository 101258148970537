import './scss/style.scss';
import MainPage from './components';
import Menu from './components/menu/menu';

const menu = new Menu();
menu.init();

MainPage.init();

(window || {}).MainPage = MainPage;
