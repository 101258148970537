const DESKTOP_WIDTH = 1224;

export default class Menu {
    constructor() {
        this.winWidth = 0;
    }

    init() {
        this.winWidth = window.innerWidth;
        this._setSubsectionsHeight();
        this._setSubsectionsTitle();
        this._setupMenuMobileOpening();
        this._setupMenuDesktopOpening();

        window.addEventListener('resize', this._reInitMenu.bind(this));
    }

    /**
     * Установка высоты левого контейнера по высоте правого контейнера с подменю с разделами меню
     * @private
     */
    _setSubsectionsHeight () {
        if (window.innerWidth >= DESKTOP_WIDTH) {
            const menuSections = Array.from(document.getElementsByClassName('menu-section__content'));
            menuSections.forEach(section => {
                const subsections = Array.from(section.querySelectorAll('.menu-subsection__content'));

                if (subsections.length) {
                    subsections.sort((sub1, sub2) => {
                        return sub1 > sub2 ? -1 : 1;
                    });
                    section.style.height = subsections[0].scrollHeight + 90 + 'px';
                }
            });
        }
    }

    /**
     * События для открытия/закрытия пунктов менню на десктопе
     * @private
     */
    _setupMenuDesktopOpening () {
        const menuSections = Array.from(document.getElementsByClassName('menu__section'));
        menuSections.forEach(section => {
            section.addEventListener('mouseleave', () => {
                if (window.innerWidth >= DESKTOP_WIDTH) {
                    this._closeOpenedSections();
                }
            });
            const subsections = Array.from(section.querySelectorAll('.menu-subsection'));
            if (subsections.length) {
                subsections.forEach(subsection => {
                    const subsectionName = subsection.querySelector('.menu-subsection__name');
                    subsectionName && subsectionName.addEventListener('mouseenter', () => {
                        if (window.innerWidth >= DESKTOP_WIDTH) {
                            this._closeOpenedSections();
                            subsection.classList.add('menu-subsection--opened');
                        }
                    })
                });
                section.addEventListener('mouseenter', () => {
                    if (window.innerWidth >= DESKTOP_WIDTH) {
                        subsections[0].classList.add('menu-subsection--opened');
                    }
                });
            }
        });
    }

    /**
     * События для открытия/закрытия пунктов менню на мобильных разрешениях
     * @private
     */
    _setupMenuMobileOpening () {
        this._setupHamburgerMenu();
        this._setupMenuSectionsClosing();

        const menuSections = Array.from(document.getElementsByClassName('menu__section'));
        menuSections.forEach(section => {
            if (!section.classList.contains('menu__section--phone') && !section.classList.contains('menu__section--customer')) {
                section.addEventListener('click', () => {
                    if (window.innerWidth < DESKTOP_WIDTH) {
                        const menuCloseSection = document.getElementById('close_section');
                        section.classList.add('menu-section--opened');
                        menuCloseSection.classList.add('menu__close-section--visible');
                        menuSections.forEach(sect => {
                            sect.classList.add('menu-section--translated');
                        });
                    }
                });
            }
            const subsections = Array.from(section.querySelectorAll('.menu__subsection'));
            if (subsections.length) {
                subsections.forEach(subsection => {
                    const subsectionName = subsection.querySelector('.menu-subsection__name');
                    subsectionName && subsectionName.addEventListener('click', () => {
                        if (window.innerWidth < DESKTOP_WIDTH) {
                            const menuCloseSection = document.getElementById('close_section');
                            const menuCloseSubsection = document.getElementById('close_subsection');
                            this._closeOpenedSections();
                            subsection.classList.add('menu-subsection--opened');
                            subsections.forEach(sect => {
                                sect.classList.add('menu-subsection--translated');
                            });
                            menuCloseSection.classList.remove('menu__close-section--visible');
                            menuCloseSubsection.classList.add('menu__close-subsection--visible');
                        }
                    })
                });
            }
        });
    }

    _setupMenuSectionsClosing () {
        const menuCloseSection = document.getElementById('close_section');
        const menuCloseSubsection = document.getElementById('close_subsection');

        menuCloseSection.addEventListener('click', () => {
            const menuSections = Array.from(document.getElementsByClassName('menu__section'));
            menuSections.forEach(sect => {
                menuCloseSection.classList.remove('menu__close-section--visible');
                sect.classList.remove('menu-section--opened');
                sect.classList.remove('menu-section--translated');
            });
        });

        menuCloseSubsection.addEventListener('click', () => {
            const menuSections = Array.from(document.getElementsByClassName('menu__subsection'));
            menuSections.forEach(sect => {
                menuCloseSubsection.classList.remove('menu__close-subsection--visible');
                menuCloseSection.classList.add('menu__close-section--visible');
                sect.classList.remove('menu-subsection--opened');
                sect.classList.remove('menu-subsection--translated');
            });
        });
    }

    _setupHamburgerMenu () {
        const hamburger = document.getElementById('hamburger');
        hamburger.addEventListener('click', () => {
            if (window.innerWidth < DESKTOP_WIDTH) {
                const menu = document.getElementById('menu');
                const menuSections = Array.from(document.getElementsByClassName('menu__section'));
                const menuSubsections = Array.from(document.getElementsByClassName('menu__subsection'));
                const menuCloseSection = document.getElementById('close_section');
                const menuCloseSubsection = document.getElementById('close_subsection');

                menu.classList.toggle('menu--opened');
                menuCloseSection.classList.remove('menu__close-section--visible');
                menuCloseSubsection.classList.remove('menu__close-subsection--visible');
                menuSections.forEach(sect => {
                    sect.classList.remove('menu-section--translated');
                    sect.classList.remove('menu-section--opened');
                });
                menuSubsections.forEach(sect => {
                    sect.classList.remove('menu-subsection--translated');
                    sect.classList.remove('menu-subsection--opened');
                });
            }
        });
    }

    _setSubsectionsTitle () {
        const menuSubsections = document.getElementsByClassName('menu-subsection__content');
        Array.from(menuSubsections).forEach(subsection => {
            const menuSubsection = subsection.closest('.menu__subsection');
            const subsectionTitle = menuSubsection && menuSubsection.querySelector('.menu-subsection__name')
                ? menuSubsection.querySelector('.menu-subsection__name').innerHTML
                : '';
            const subsectionTitleEl = document.createElement('div');
            subsectionTitleEl.classList.add('menu-subsection__title');
            subsectionTitleEl.innerHTML = subsectionTitle;
            subsection.prepend(subsectionTitleEl);
        });
    }

    _reInitMenu (e) {
        if ((this.winWidth <= DESKTOP_WIDTH && window.innerWidth >= DESKTOP_WIDTH)
            || (this.winWidth >= DESKTOP_WIDTH && window.innerWidth <= DESKTOP_WIDTH)) {
            const menu = document.getElementById('menu');
            menu.classList.remove('menu--opened');
            this._closeOpenedSections();
        }
        this._setSubsectionsHeight();
    }

    _closeOpenedSections () {
        Array.from(document.getElementsByClassName('menu-subsection--opened')).forEach(element => {
            element.classList.remove('menu-subsection--opened');
        })
    }
}
