import Slider from './slider/slider';
import Viewer from "./viewer";

let slider;

const MainPage = {
    init() {
        const container = document.getElementById('slider-container');
        slider = new Slider(Viewer);
        slider.init(container);
    },
    destroy() {
        slider.destroy();
        Viewer.destroy();
        slider = null;
    }
};

export default MainPage
