import Viewer from "../viewer";

let slides = [],
    slidesControls = [],
    slidesCount = null,
    slidingDirection, hold = false,
    currentIndex = 0,
    isScrollableSlide = false;

let toLastCanvasSlide = false;

let timerId = null;

function wait() {
    hold = true;
    if (timerId) {
        clearTimeout(timerId);
        hold = true;
    }
    timerId = setTimeout(() => {
        hold = false;
    }, 300)
}

function debounce(fn, time) {
    const _time = time;
    const _fn = fn;
    let timerId = null;
    const callback = function (...args) {
        if (timerId) {
            return;
        }
        timerId = setTimeout(() => {
            _fn(...args);
            timerId = null;
        }, _time);
    };

    callback.cancel = () => {
        clearTimeout(timerId);
        timerId = null
    };

    return callback;
}

function detectDirection(e) {
    if (e.deltaY < 0) {
        slidingDirection = 'down';
    }
    if (e.deltaY > 0) {
        slidingDirection = 'up';
    }

    if (!isScrollableSlide) {
        e.stopPropagation();
    }
}

let initiated = false;

export default class Slider {

    constructor(viewer) {
        this.viewer = viewer;
        this.f = debounce(() => {
            isScrollableSlide = false;
            this.container.classList.remove('slider-container--scrollable');
            document.getElementById('header').classList.remove('header--scrollable');
            toLastCanvasSlide = true;
            this.toggleSlide(this.container, 4);
        }, 800)

        this._boundScrollY = this._scrollY.bind(this);
    }

    init(container) {
        if (initiated) {
            return;
        }

        this.container = container;
        slides = document.querySelectorAll('.slide');
        slidesCount = slides.length;

        this.viewer.onAnimationFinished(() => {
            if (toLastCanvasSlide) {
                hold = true;
                setTimeout(() => {
                    toLastCanvasSlide = false;
                    hold = false;
                }, 2500)
            } else {
                wait()
            }
        });
        this.viewer.init().then(() => {
            this.generateControls(container);

            slidingDirection = false;
            hold = false;

            this.container.style.transform = 'translateY(0)';
            window.addEventListener('wheel', detectDirection);
            window.addEventListener('wheel', this._boundScrollY);
            this._swipe(container);
            this.animateSlides();
            initiated = true;
        });
    }

    destroy() {
        if (!initiated) {
            return;
        }

        this._controlsContainer.parentElement.removeChild(this._controlsContainer);

        window.removeEventListener('wheel', detectDirection);
        window.removeEventListener('wheel', this._boundScrollY);

        document.removeEventListener('touchstart', this._swipeTouchStart, {passive: false});
        document.removeEventListener('touchmove', this._swipeTouchMove, {passive: false});
        document.removeEventListener('touchend', this._swipeTouchEnd, false);

        initiated = false;
        currentIndex = 0;
    }

    _scrollY(container, slideIndex = undefined) {
        let slength, step = 100;

        slength = parseInt(this.container.style.transform.replace('translateY(', ''));

        if (slideIndex !== undefined) {
            slength = slideIndex !== currentIndex ? slength - step * (slideIndex - currentIndex) : slength;
        } else if (slidingDirection === 'up' && currentIndex < slidesCount - 1) {
            slength = slength - step;
        } else if (slidingDirection === 'down' && slength < 0) {
            slength = slength + step;
        }
        if (hold === false) {
            if (isScrollableSlide && slideIndex === undefined) {
                this._scrollScrollable(this.container);

                return true;
            }

            const media = document.getElementById('media');
            hold = true;
            this.container.style.transform = 'translateY(' + slength + 'vh)';
            slidingDirection === 'up'
                ? currentIndex < slidesCount - 1 ? currentIndex++ : currentIndex
                : currentIndex > 0 ? currentIndex-- : currentIndex;
            if (slideIndex !== undefined) {
                currentIndex = slideIndex;
            }
            this.animateSlides();
            if (slides[currentIndex].classList.contains('slide--no-media')) {
                media.classList.add('media--invisible');
                document.getElementById('header').classList.add('header--scrollable');
                this.container.classList.add('slider-container--scrollable');
                isScrollableSlide = true;

                wait();
            } else {
                isScrollableSlide = false;
                media.classList.remove('media--invisible');
                Viewer.change(currentIndex);
            }
        }
    }

    _scrollScrollable() {
        if (slidingDirection === 'down' && slides[currentIndex].scrollTop === 0) {
            this.f();
        } else {
            this.f.cancel();
        }
    }

    _swipe(container) {
        let swdir,
            sX,
            sY,
            dX,
            dY,
            threshold = 100,
            /*[min distance traveled to be considered swipe]*/
            slack = 100,
            /*[max distance allowed at the same time in perpendicular direction]*/
            alT = 500,
            /*[max time allowed to travel that distance]*/
            elT, /*[elapsed time]*/
            stT; /*[start time]*/
        this._swipeTouchStart = (e) => {
            let tchs = e.changedTouches[0];
            swdir = 'none';
            sX = tchs.pageX;
            sY = tchs.pageY;
            stT = new Date().getTime();
            // e.preventDefault();
        };
        document.addEventListener('touchstart', this._swipeTouchStart, {passive: false});

        this._swipeTouchMove =  (e) => {
            // e.preventDefault(); /*[prevent scrolling when inside DIV]*/
        };
        document.addEventListener('touchmove', this._swipeTouchMove, {passive: false});

        this._swipeTouchEnd = (e) => {
            let tchs = e.changedTouches[0];
            dX = tchs.pageX - sX;
            dY = tchs.pageY - sY;
            elT = new Date().getTime() - stT;
            if (elT <= alT) {
                if (Math.abs(dX) >= threshold && Math.abs(dY) <= slack) {
                    swdir = (dX < 0) ? 'left' : 'right';
                } else if (Math.abs(dY) >= threshold && Math.abs(dX) <= slack) {
                    swdir = (dY < 0) ? 'up' : 'down';
                }
                if (container) {
                    if (swdir === 'up') {
                        slidingDirection = swdir;
                        this._scrollY(container);
                    } else if (swdir === 'down' && container.style.transform !== 'translateY(0)') {
                        slidingDirection = swdir;
                        this._scrollY(container);
                    }
                    e.stopPropagation();
                }
            }
        };
        document.addEventListener('touchend', this._swipeTouchEnd, false);
    }

    generateControls(container) {
        const parentContainer = container.parentElement;
        this._controlsContainer = document.createElement('div');
        this._controlsContainer.classList.add('slider-controls');
        parentContainer.appendChild(this._controlsContainer);

        slides.forEach((slide, index) => {
            const control = document.createElement('div');
            control.classList.add('slider-controls__item', 'control-item');

            const controlText = document.createElement('div');
            controlText.classList.add('control-item__text');
            controlText.innerHTML = (index + 1).toString().padStart(2, '0');

            control.appendChild(controlText);
            this._controlsContainer.appendChild(control);

            control.addEventListener('click', () => this.toggleSlide(container, index))
        });
        slidesControls = document.querySelectorAll('.slider-controls__item');
        slidesControls[0].classList.add('control-item--active');
    }

    animateSlides() {
        slides.forEach((slide, index) => {
            if (index === currentIndex) {
                slide.classList.remove('slide--inactive');
                return;
            }
            slide.classList.add('slide--inactive');
        });

        slidesControls.forEach(control => control.classList.remove('control-item--active'));
        slidesControls[currentIndex].classList.add('control-item--active');
    }

    toggleSlide(container, index) {
        if (hold) {
            return;
        }
        document.body.classList.remove('body--scrollable');

        slidesControls.forEach(control => control.classList.remove('control-item--active'));
        slidesControls[index].classList.add('control-item--active');

        this._scrollY(container, index)
    }


}
